.full {
  display: flex;
  justify-content: center;
  // Full height - Navbar and Page title
  height: calc(100vh - 160px);
  align-items: center;
  & .icon {
    font-size: 80px;
  }
}

.box {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.boxCenter {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: black 1px solid;
  border-radius: 16px;
}

.float {
  position: absolute;
  &.anchorRight {
    right: -32px;
    top: 0;
  }
  &.anchorLeft {
    left: -32px;
    top: 0;
  }
}

.icon {
  color: inherit;
  animation: pulse cubic-bezier(0.96, 0, 0.5, 1) 1.25s;
  animation-iteration-count: infinite;
  font-size: inherit;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }
  98% {
    opacity: 0.6;
    transform: scaleX(0.8) scaleY(0.8);
  }
  100% {
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }
}
