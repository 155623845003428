.root {
  flex-grow: 1;
}

.menuButton {
  margin-right: 16px;
}

.title {
  flex-grow: 1;
}

.menuIcon {
  margin-right: 8px;
}
