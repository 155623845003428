// @import "../../../util/theme/palette.scss";
$secondary: #1976d2;

$white: #e2e2e2;
$black: #212121;

.root {
  display: flex;
  flex-wrap: wrap;
  margin: 0 36px;
  color: $black;
}

.title {
  color: #c02121;
}

.message {
  white-space: pre-wrap;
}

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resetButton {
  margin-top: 36px;
  padding: 8px;
  border-radius: 8px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  color: $white;
  background-color: $secondary;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: rgba($secondary, 0.8);
  }
}
